<!--
 * @Author: wang peng
 * @Date: 2023-2-28
 * @Description: 图表组件
-->
<template>
  <div class="charts-box" :id="lineChartsId + 'box'">
    <!-- 有数据 -->
    <div v-if="isData" class="echarts-charts">
      <div :id="lineChartsId" class="charts"></div>
    </div>
    <!-- 无数据 -->
    <div v-else class="no-charts">
      <!-- 加载框 -->
      <div class="loading-box-bg" v-if="isShowLoading">
        <div class="loading-info">
          <div class="img loading-img"></div>
        </div>
      </div>
      <!-- 没有数据显示 -->
      <div v-else class="echarts-nodata">
        <div class="nodata-box">
          <div class="nodata-pic"></div>
          <div class="nodata-title text-center">暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as echarts from 'echarts';

  export default {
    name: 'YkcChartsLine',
    props: {
      // echars配置
      chartOption: {
        type: Object,
        default: () => ({}),
      },
      // 判断是否有数据
      isData: {
        type: Boolean,
        default: false,
      },
      isShowLoading: {
        // 是否需要加载动画
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        myObserver: null, // 监控chart div 宽高度变化
        myChart: null, // 图表对象
        lineChartsId: `lineChartsId-${Math.random().toString(36).substring(3, 8)}`, // 组件id
      };
    },
    watch: {
      chartOption: {
        immediate: true,
        deep: true,
        handler(val) {
          if (Object.keys(val).length > 0) {
            this.$nextTick(() => {
              this.drawLine();
            });
          }
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        // 在div发生改变时调整echarts的尺寸
        this.myObserver = new ResizeObserver(() => {
          console.log('ResizeObserver change line this.myChart ', this.myChart);
          setTimeout(() => {
            if (this.myChart) {
              this.myChart.resize();
            }
          });
        });
        this.myObserver.observe(document.getElementById(`${this.lineChartsId}box`));
      });
    },
    methods: {
      /**
       * 初始化图表
       */
      drawLine() {
        // 基于准备好的dom，初始化echarts实例
        const myChart = echarts.init(document.getElementById(this.lineChartsId));
        // 绘制图表
        myChart.setOption(this.chartOption);
        this.myChart = myChart;
      },
    },
    beforeDestroy() {
      if (this.myObserver && document.getElementById(`${this.lineChartsId}box`)) {
        this.myObserver.unobserve(document.getElementById(`${this.lineChartsId}box`));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
  };
</script>

<style lang="scss">
  .charts-box {
    position: relative;
    width: 100%;
    height: 100%;
    .echarts-charts {
      width: 100%;
      height: 100%;

      .charts {
        width: 100%;
        height: 100%;
        background: #ffffff;
      }
    }
    .no-charts {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;

      .echarts-nodata {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        min-height: 120px;
        margin-top: -60px;
        .nodata-pic {
          width: 100px;
          height: 100px;
          background-image: url('~@/assets/images/no-data.png');
          background-size: contain;
        }

        .nodata-title {
          font-size: 14px;
          color: #9b9b9b;
          text-align: center;
        }
      }
    }
    /** 局部loading */
    .loading-box-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ebebf0;

      .loading-info {
        position: relative;
        top: 50%;
        left: 50%;
        width: 70px;
        height: 70px;
        margin-top: -35px;
        margin-left: -35px;

        .img {
          width: 70px;
          height: 70px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 70px;
          background-image: url('~@/assets/images/loading.gif');
        }
      }
    }
  }
</style>
